export const messages = {
  // Common
  "common.required": "Obligatorio",
  "common.iWantMySas": "Quiero mi S.A.S.",
  "common.continue": "Continuar",
  "common.upload": "Subir documentos",
  "common.back": "Volver",
  "common.email": "Correo electrónico",
  "common.phone": "Teléfono",
  "common.firstName": "Nombre",
  "common.lastName": "Apellido",
  "common.send": "Enviar",
  "common.option": "Opción",
  "common.address": "Dirección",
  "common.city": "Ciudad",
  "common.department": "Departamento",
  "common.province": "Provincia",
  "common.activity": "Actividad",
  "common.view": "Ver",
  "common.cbu": "CBU",
  "common.shareHolder": "Accionista",
  "common.cuit": "CUIT",
  "common.dni": "DNI",
  "common.maritalStatus": "Estado civil",
  "common.profession": "Profesión",
  "common.capitalContribution": "Aporte sobre el capital",
  "common.mustOnlyContainNumbers": "Sólo puede contener números",
  "common.administrator": "Administrador",
  "common.mustBeValidDate": "Debe ser una fecha válida",
  "common.backToHomePage": "Volver a la página principal",
  "common.autoFill": "Autocompletar",
  "common.language": "Idioma",
  "common.spanish": "Español",
  "common.english": "Inglés",
  "common.close": "Cerrar",
  "common.typeNewValues":
    "Escribir nuevos valores. Presionar Enter para confirmar",
  "common.noOptions": "Sin resultados",
  "common.otherActivities": "Otras actividades",
  "common.isRegisteredTrademark": "Por favor indicar si alguna de las alternativas corresponde a una marca propia registrada.",
  "common.isForeign": "Indique si no posee domicilio en {jurisdiction}. En caso de encontrarse fuera de la ciudad, se aplicará un cargo adicional por la gestión.",
  "common.provinceOfCordoba": "Provincia de Córdoba",
  "common.ciudadAutonomaDeBuenosAires": "Ciudad Autónoma de Buenos Aires",

  // Home
  "home.sasConstitution":
    "Constitución de una Sociedad por Acciones Simplificada (S.A.S.)",
  "home.sasJurisdiction": "En la Jurisdicción de la Provincia de Córdoba",
  "home.whyCordoba": "¿Por qué en la Provincia de Córdoba?",
  "home.whyCordobaAnswer1":
    "A diferencia de otras jurisdicciones, la Dirección General de Inspección de Personas Jurídicas de la Provincia de Córdoba ha desarrollado y permitido la constitución de sociedades con una gran celeridad y facilidad, permitiendo la obtención de la CUIT y su respectiva Matrícula de Inscripción en tiempos que oscilan entre los dos (2) y cinco (5) días hábiles desde que se ingresa el trámite, pudiendo gestionar todo de manera online y sin la necesidad de acudir a ninguna dependencia pública.",
  "home.whyCordobaAnswer2":
    "Para mayor información, te sugerimos leer la sección de Beneficios y Preguntas Frecuentes.",
  "home.whatIsSas": "¿Qué es una S.A.S.?",
  "home.whatIsSasDescription":
    "Es un tipo societario establecido por la Ley de Apoyo al Capital Emprendedor que tiene como objetivo principal propiciar la actividad emprendedora y de generación de capital en el país, así como su expansión internacional.",
  "home.constituteYourSas": "Constituí tu S.A.S.",
  "home.inCordobaProvince": "En la provincia de Córdoba",
  "home.operationalNationwide": "Funcional en todo el país",

  // Benefits
  "benefits.benefits": "Beneficios",
  "benefits.whatAreTheBenefits": "¿Cuáles son los beneficios?",
  "benefits.fast": "Rápido",
  "benefits.fast.bulletPoint1":
    "Desde solicitada la inscripción, tu S.A.S. no demorará más de 5 días hábiles en contar con CUIT, Matrícula y estar 100% operativa.",
  "benefits.fast.bulletPoint2": "La obtención del CUIT es automática.",
  "benefits.easy": "Fácil",
  "benefits.easy.bulletPoint1":
    "La Jurisdicción de la Provincia de Córdoba es una de las únicas donde se puede constituir una S.A.S. sin restricciones y en tiempos cortos.",
  "benefits.easy.bulletPoint2":
    "Completado el formulario de datos requeridos, ya estaremos en condiciones de iniciar el trámite.",
  "benefits.easy.bulletPoint3":
    "Se utiliza un estatuto modelo pre-aprobado que permite evitar observaciones por parte del órgano de contralor.",
  "benefits.economical": "Económico",
  "benefits.economical.bulletPoint1":
    "Sus costos son notoriamente inferiores a otros tipos societarios como las Sociedades Anónimas (S.A.) y las Sociedades de Responsabilidad Limitada (S.R.L.).",
  "benefits.economical.bulletPoint2":
    "Tiene un capital mínimo de dos salarios mínimos vitales y móviles.",
  "benefits.digital": "Digital",
  "benefits.digital.bulletPoint1":
    "Toda la documentación requerida puede firmarse digitalmente.",
  "benefits.digital.bulletPoint2":
    "Podrás tener los registros societarios y contables en formato digital.",

  // Procedure
  "procedure.procedure": "Procedimiento",
  "procedure.whatIsTheProcedureLike": "¿Cómo es el procedimiento?",
  "procedure.procedure1.title": "Reserva de denominación",
  "procedure.procedure1.text":
    "Informanos el nombre que te gustaría que tenga tu S.A.S. y solicitaremos una reserva de denominación para poder validar su disponibilidad.",
  "procedure.procedure2.title": "Carga de datos",
  "procedure.procedure2.text":
    "Si tu denominación se encuentra disponible, te solicitaremos a través de un sencillo formulario,una serie de datos para avanzar con la constitución de tu S.A.S.",
  "procedure.procedure3.title": "Integración del capital",
  "procedure.procedure3.text":
    "Deberás depositar en una cuenta que se abrirá especialmente para tu Sociedad el equivalente al 25% del capital social.",
  "procedure.procedure4.title": "Revisión de acta constitutiva",
  "procedure.procedure4.text":
    "Una vez contemos con todos los datos requeridos, y se hubiera acreditado en cuenta la transferencia del capital social requerido, te remitiremos un Borrador de Acta Constitutiva y Estatuto para controlar que todos los datos allí plasmados sean correctos.",
  "procedure.procedure5.title": "Firma y certificación de documentos",
  "procedure.procedure5.text":
    "Te remitiremos las versiones definitivas de los documentos constitutivos de tu S.A.S., así como otra documentación complementaria que pueda exigir el Registro Público, con indicación precisa de las modalidades y tipos de firmas requeridas.",
  "procedure.procedure6.title": "Ingreso del pedido de inscripción",
  "procedure.procedure6.text":
    "Una vez remitida la documentación requerida, se ingresará formalmente el pedido de inscripción de tu S.A.S.",
  "procedure.procedure7.title": "Obtención de CUIT",
  "procedure.procedure7.text":
    "Una vez aprobado el pedido de inscripción por parte del Registro Público, se generará automáticamente el CUIT de tu S.A.S.",
  "procedure.procedure8.title": "Publicación en Boletín Oficial",
  "procedure.procedure8.text":
    "Dando cumplimiento al deber de información, se publicará por un día en el Boletín Oficial los datos más relevantes de tu S.A.S.",
  "procedure.procedure9.title": "Resolución de inscripción",
  "procedure.procedure9.text":
    "Culminado el procedimiento, el Registro Público emitirá la respectiva Resolución de Inscripción y asignación del número de Matrícula de tu S.A.S. Se remitirá en documento firmado digitalmente en formato .pdf",
  "procedure.procedureReady.title": "¡Listo!",
  "procedure.procedureReady.text":
    "Tu S.A.S. se encuentra plenamente operativa y preparada para que comiences a realizar cualquier tipo de negocio u operación.",
  "procedure.completeTheFormTitle": "Completa el formulario",
  "procedure.completeTheFormText":
    "Simplemente tomate unos minutos para rellenar algunos datos y nosotros nos encargaremos de confeccionar todos los documentos necesarios para constituir tu sociedad y de acompañarte en todo el proceso.",

  // Costs
  "costs.costs": "Costos",
  "costs.whatAreTheCostsInvolved": "¿Qué costos tiene?",
  "costs.expenses": "Gastos",
  "costs.expenses.ipj.1": "Tasa Retributiva de Servicios de IPJ: {pesos}.",
  "costs.expenses.ipj.2": "Valor vigente hasta el {validity}.",
  "costs.expenses.signatureCertification.1": "Certificaciones de firma.",
  "costs.expenses.signatureCertification.2":
    "Consultar honorarios con un profesional notarial.",
  "costs.expenses.deposit.1": "Depósito del 25% del capital social.",
  "costs.expenses.deposit.2":
    "Será reintegrado a una cuenta bancaria dentro de 72hs hábiles de constituida la sociedad.",
  "costs.professionalFees": "Honorarios",
  "costs.professionalFees.1": "Nuestros honorarios son de {pesos}.",
  "costs.professionalFees.2": "Valor vigente hasta el {validity}",
  "costs.paymentMethod": "Forma de pago",
  "costs.paymentMethod.1":
    "Deberán abonarse la totalidad de los gastos y un anticipo de Honorarios equivalente al cincuenta por ciento (50%) al momento de encomendar el trámite y el restante cincuenta por ciento (50%) al obtenerse la Resolución de Inscripción y Matrícula de tu S.A.S.",
  "costs.disclaimer":
    "* Los gastos relativos a la transferencia del veinticinco por ciento (25%) del capital social puede ser realizado directamente por el usuario o remitirnos a nuestro equipo el dinero para que nos encarguemos de ello. Luego, la devolución será a una cuenta de titularidad de los accionistas.",

  // FAQ
  "faq.faq": "Preguntas frecuentes",
  "faq.question1":
    "No soy de Córdoba, ¿igual puedo constituir una S.A.S. en Córdoba?",
  "faq.answer1":
    "Sí. La única salvedad que deberás tener en cuenta es que la sede social de tu S.A.S. deberá estar fijada dentro de la provincia de Córdoba. Si no dispones de un domicilio donde fijarla, contáctate con nosotros para que podamos ayudarte.",
  "faq.question2":
    "¿Qué diferencias hay entre una S.A.S., una S.A. y una S.R.L.?",
  "faq.answer2":
    "Si bien los tres tipos societarios son plenamente útiles y eficaces para desarrollar un negocio o actividad, la S.A.S. permite una mayor flexibilidad y simpleza en su constitución y posterior desenvolvimiento. Si te interesa saber en detalle las principales diferencias entre cada uno de los tipos societarios más utilizados en nuestro país, te invitamos a leer el siguiente informe: …",
  "faq.question3":
    "¿Qué tipo de actividad o negocios puedo realizar con mi S.A.S.?",
  "faq.answer3":
    "La S.A.S. tiene la posibilidad de prever múltiples actividades posibles para desarrollar. Se puede optar entre un objeto múltiple pre-aprobado por el órgano de contralor o diseñar uno en concreto para la Sociedad. No podrán ser objeto de una S.A.S.: a) operaciones de capitalización, ahorro o en cualquier forma requieran dinero o valores al público con promesas de prestaciones o beneficios futuros; b) explotación de concesión o servicios públicos.",
  "faq.question4":
    "¿Puedo ser accionista o administrador de una S.A.S. siendo extranjero?",
  "faq.answer4":
    "Sí. De todas maneras, atento a tratarse de un supuesto de excepción, el procedimiento y los requisitos a cumplimentar pueden variar del trámite regular. En caso de que seas extranjero y desees formar parte de una S.A.S. te invitamos a contactarte con nosotros para darte una atención personalizada y evaluar tu caso.",
  "faq.question5": "¿Puedo constituir una S.A.S. unipersonal?",
  "faq.answer5":
    "Sí. Una S.A.S. puede ser constituida con una sola persona como socia. De todas maneras, necesitarás de una segunda persona para llenar los cargos del órgano de administración de la sociedad, sin necesidad que esa persona sea además socia de la Sociedad.",
  "faq.question6":
    "¿Cuál es el capital mínimo requerido para constituir una S.A.S.?",
  "faq.answer6":
    "El capital social no podrá ser inferior al importe equivalente a dos (2) veces el salario mínimo vital y móvil que se establezca al momento de su constitución.",
  "faq.question7": "¿Cómo funcionan los Libros Digitales?",
  "faq.answer7":
    "Las S.A.S. llevan obligatoriamente Libros Digitales donde deberán asentarse todos los actos de la Sociedad. Su acceso se realiza a través de un Portal dispuesto por la autoridad de contralor y desde allí se pueden visualizar y cargar todas las actas y documentos pertinentes, permaneciendo los mismos inalterables en una nube de acceso único para los administradores de la Sociedad y/o quienes ellos designen a tales efectos. Si deseas mayor asesoramiento respecto a libros digitales, te solicitamos te comuniques con nosotros para poder ayudarte.",
  "faq.question8":
    "¿Qué actividades económicas comprende el objeto pre-aprobado por DIPJ?",
  "faq.answer8":
    "El objeto pre-aprobado comprende las siguientes actividades:",
  "faq.answer8.1":
    "Construcción de todo tipo de obras, públicas o privadas, edificios, viviendas, locales comerciales y plantas industriales; realizar refacciones, remodelaciones, instalaciones, trabajos de albañilería y/o cualquier trabajo de la construcción.",
  "faq.answer8.2":
    "Transporte nacional o internacional de cargas en general, ya sea por vía terrestre, aérea o marítima, con medios de transporte propios o de terceros, pudiendo realizar todo lo inherente a su logística.",
  "faq.answer8.3":
    "Compra, venta y permuta, explotación, arrendamientos y administración de bienes inmuebles, urbanos y rurales y la realización de operaciones de propiedad horizontal.",
  "faq.answer8.4":
    "Realizar toda clase de operaciones financieras por todos los medios autorizados por la legislación vigente. Se exceptúan las operaciones comprendidas en la Ley de Entidades Financiera.",
  "faq.answer8.5":
    "Realizar la explotación directa por sí o por terceros en establecimientos rurales, ganaderos, agrícolas, avícolas, frutícolas, vitivinícolas, forestales, cría, venta y cruza de ganado, explotación de tambos, cultivos, compra, venta y acopio de cereales.",
  "faq.answer8.6":
    "Elaboración, producción, transformación y comercialización de productos y subproductos alimenticios de todo tipo, expendio de todo tipo de bebidas, explotación de servicio de catering, de concesiones gastronómicas, bares, restoranes, comedores, organización y logística en eventos sociales.",
  "faq.answer8.7":
    "Creación, producción, elaboración, transformación, desarrollo, reparación, implementación, servicio técnico, consultoría, comercialización, distribución, importación y exportación de softwares, equipos informáticos, eléctricos y electrónicos.",
  "faq.answer8.8":
    "Producción, organización y explotación de espectáculos públicos y privados, teatrales, musicales, coreográficos, desfiles, exposiciones, ferias, conciertos musicales, recitales, y eventos sociales.",
  "faq.answer8.9":
    "Explotación de agencia de viajes y turismo, pudiendo realizar reservas y ventas de pasajes, terrestres, aéreos, marítimos, nacionales o internacionales; organización, reserva y ventas de excursiones, reservas de hotelería, reserva, organización y ventas de charters y traslados, dentro y fuera del país de contingentes.",
  "faq.answer8.10":
    "Organización, administración, gerenciamiento y explotación de centros médicos asistenciales, con atención polivalente e integral de medicina, atención clínica, terapéutica y quirúrgica, con o sin internación y demás actividades relacionadas a la salud y servicios de atención médica.",
  "faq.answer8.11":
    "Constituir, instalar y comercializar editoriales y gráficas en cualquier soporte.",
  "faq.answer8.12":
    "Instalación y explotación de establecimientos destinados a la industrialización, fabricación y elaboración de las materias primas, productos y subproductos relacionados directamente con su objeto social.",
  "faq.answer8.13": "Importación y exportación de bienes y servicios.",
  "faq.answer8.14":
    "Actuar como fiduciante, fiduciaria, beneficiaria, fideicomisaria, por cuenta propia o por cuenta de terceros y/o asociada a terceros, en todo tipo de emprendimientos.",
  "faq.answer8.caba":
    "El objeto pre-aprobado por la IGJ en CABA comprende las siguientes actividades:",
  "faq.answer8.caba.1":
    "Dedicarse, por cuenta propia o ajena o asociada con terceros, ya sea dentro o fuera del país, a la creación, producción, intercambio, fabricación, transformación, industrialización, comercialización, intermediación, representación, importación y exportación de toda clase de bienes materiales, incluso recursos naturales, e inmateriales y la prestación de toda clase de servicios, relacionados directa o indirectamente con las siguientes actividades:",
  "faq.answer8.caba.2":
    "Agropecuarias, avícolas, ganaderas, pesqueras, tamberas y vitivinícolas;",
  "faq.answer8.caba.3":
    "Comunicaciones, espectáculos, editoriales y gráficas en cualquier soporte;",
  "faq.answer8.caba.4":
    "Industrias manufactureras de todo tipo;",
  "faq.answer8.caba.5":
    "Culturales y educativas; (e) Desarrollo de tecnologías, investigación e innovación y software;",
  "faq.answer8.caba.6":
    "Gastronómicas, hoteleras y turísticas;",
  "faq.answer8.caba.7":
    "Inmobiliarias y constructoras;",
  "faq.answer8.caba.8":
    "Inversoras, financieras y fideicomisos;",
  "faq.answer8.caba.9":
    "Petroleras, gasíferas, forestales, mineras y energéticas en todas sus formas;",
  "faq.answer8.caba.10":
    "Salud;",
  "faq.answer8.caba.11":
    "Transporte",
  "faq.answer8.caba.12":
    "La sociedad tiene plena capacidad de derecho para realizar cualquier acto jurídico en el país o en el extranjero, realizar toda actividad lícita, adquirir derechos y contraer obligaciones.",
  "faq.answer8.caba.13":
    "Para la ejecución de las actividades enumeradas en su objeto, la sociedad puede realizar inversiones y aportes de capitales a personas humanas y/o jurídicas, actuar como fiduciario y celebrar contratos de colaboración; comprar, vender y/o permutar toda clase de títulos y valores;",
  "faq.answer8.caba.14":
    "Tomar y otorgar créditos y realizar toda clase de operaciones, financieras, excluidas las reguladas por la Ley de Entidades Financieras y toda otra que requiera el concurso y/o ahorro público.",

  "faq.question9": "¿Es posible redactar mis propias previsiones estatutarias?",
  "faq.answer9":
    "Sí. En caso que desees un estatuto confeccionado de acuerdo a necesidades específicas, te pedimos nos contactes para poder evaluar el asunto e informarte los pasos a seguir.",

  // Contact
  "contact.contact": "Contacto",
  "contact.name": "Nombre",
  "contact.lastName": "Apellido",
  "contact.company": "Empresa",
  "contact.contactPhone": "Teléfono de contacto",
  "contact.message": "Mensaje",
  "contact.sendMyData": "Enviar mis datos",
  "contact.invalidEmail": "Correo electrónico inválido",
  "contact.emailSent": "Tu información de contacto fue enviada exitosamente!",

  // Footer
  "footer.text":
    "QuieroMiSAS presta sus servicios como intermediario para la realización de trámites legales ante la Dirección General de Inspección de Personas Jurídicas, para lo cual contrata servicios de terceros ubicados en el país, sin formar parte de ningún consorcio, entidades gubernamentales, entidades estatales, o cualquier otra clase de entidades que otorguen derechos de registrabilidad de empresas en la provincia de Córdoba; tampoco tiene alianzas, sociedad comercial o relación directa alguna con bancos en el país. QuieroMiSAS no presta ni pretende prestar ningún tipo de servicio, asesoramiento o consejo fiscal o jurídico a sus clientes y usuarios. Todo tipo de información publicada al respecto corresponde exclusivamente a información pública.",
  "footer.privacyPolicy": "Política de privacidad",
  "footer.privacyPolicy.title1": "Política de Privacidad de QuieroMiSAS",
  "footer.privacyPolicy.text1":
    'Esta Política de Privacidad describe cómo QuieroMiSAS ("nosotros", "nuestro" o "nuestros") recopila, utiliza y protege la información personal que usted proporciona al utilizar nuestro sitio web. Al utilizar nuestro sitio, usted acepta las prácticas descritas en esta política.',
  "footer.privacyPolicy.title2": "Información que Recopilamos",
  "footer.privacyPolicy.text2.1":
    "Podemos recopilar la siguiente información personal cuando usted utiliza nuestro sitio web:",
  "footer.privacyPolicy.text2.2":
    "Información de contacto, como nombre, dirección de correo electrónico y número de teléfono.",
  "footer.privacyPolicy.text2.3":
    "Información demográfica, como país, provincia y ciudad.",
  "footer.privacyPolicy.text2.4":
    "Información sobre su negocio o sociedad, como el nombre de la empresa, tipo de sociedad y detalles relacionados con la constitución de sociedades.",
  "footer.privacyPolicy.text2.5":
    "Información de pago, en caso de que realice transacciones a través de nuestro sitio web.",
  "footer.privacyPolicy.text2.6":
    "Información de inicio de sesión, en caso de que cree una cuenta en nuestro sitio web.",
  "footer.privacyPolicy.title3": "Cómo Utilizamos su Información",
  "footer.privacyPolicy.text3.1":
    "Utilizamos la información recopilada para los siguientes propósitos:",
  "footer.privacyPolicy.text3.2":
    "Procesar y completar sus solicitudes de constitución de sociedades y otros servicios relacionados.",
  "footer.privacyPolicy.text3.3":
    "Comunicarnos con usted sobre su solicitud y proporcionarle información relevante.",
  "footer.privacyPolicy.text3.4":
    "Mejorar nuestros servicios y personalizar su experiencia en nuestro sitio web.",
  "footer.privacyPolicy.text3.5":
    "Administrar y mantener su cuenta, si ha creado una.",
  "footer.privacyPolicy.text3.6":
    "Cumplir con nuestras obligaciones legales y reguladoras.",
  "footer.privacyPolicy.title4": "Divulgación de Información",
  "footer.privacyPolicy.text4.1":
    "No vendemos ni alquilamos su información personal a terceros. Podemos compartir su información con:",
  "footer.privacyPolicy.text4.2":
    "Proveedores de servicios que nos ayudan a brindar servicios y operar nuestro sitio web.",
  "footer.privacyPolicy.text4.3":
    "Autoridades legales o gubernamentales en respuesta a una orden judicial u otra solicitud legal válida.",
  "footer.privacyPolicy.text4.4":
    "En caso de fusión, adquisición o venta de activos, donde su información podría transferirse como parte de dicha transacción.",
  "footer.privacyPolicy.title5": "Seguridad de la Información",
  "footer.privacyPolicy.text5":
    "Tomamos medidas razonables para proteger su información personal contra accesos no autorizados, uso indebido o divulgación. Sin embargo, ninguna transmisión por Internet o almacenamiento electrónico es completamente seguro.",
  "footer.privacyPolicy.title6": "Sus Derechos",
  "footer.privacyPolicy.text6":
    "Usted tiene derechos sobre su información personal, incluido el derecho a acceder, corregir, actualizar o eliminar la información que tenemos sobre usted. Si desea ejercer alguno de estos derechos, por favor contáctenos a través de contacto@quieromisas.com.",
  "footer.privacyPolicy.title7": "Cambios en nuestra Política de Privacidad",
  "footer.privacyPolicy.text7.1":
    "Podemos actualizar nuestra Política de Privacidad ocasionalmente para reflejar cambios en nuestras prácticas y servicios. Cualquier cambio significativo se reflejará en esta página.",
  "footer.privacyPolicy.text7.2":
    "Si tiene alguna pregunta o inquietud sobre nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros a través de contacto@quieromisas.com.",
  "footer.privacyPolicy.text7.3":
    "Al utilizar nuestro sitio web, usted acepta las prácticas descritas en esta Política de Privacidad.",

  "footer.cookiesPolicy": "Política de cookies",
  "footer.cookiesPolicy.title1": "Política de Cookies de QuieroMiSAS",
  "footer.cookiesPolicy.text1":
    'Esta Política de Cookies explica cómo QuieroMiSAS ("nosotros", "nuestro" o "nuestros") utiliza cookies y tecnologías similares en nuestro sitio web. Al utilizar nuestro sitio web, usted acepta el uso de cookies de acuerdo con esta política. Si no está de acuerdo con el uso de cookies, le recomendamos que ajuste la configuración de su navegador o que deje de utilizar nuestro sitio web.',
  "footer.cookiesPolicy.title2": "¿Qué son las cookies?",
  "footer.cookiesPolicy.text2":
    "Las cookies son pequeños archivos de texto que se almacenan en su dispositivo (computadora, tableta, teléfono móvil, etc.) cuando visita nuestro sitio web. Estas cookies permiten que el sitio web recuerde sus acciones y preferencias (como inicio de sesión, idioma, tamaño de fuente y otras preferencias de visualización) durante un período de tiempo, para que no tenga que introducirlos nuevamente cada vez que visite el sitio o navegue de una página a otra.",
  "footer.cookiesPolicy.title3":
    "¿Qué tipos de cookies utilizamos y para qué fines?",
  "footer.cookiesPolicy.subtitle3.1": "Cookies Esenciales",
  "footer.cookiesPolicy.text3.1":
    "Estas cookies son necesarias para que el sitio web funcione correctamente. No recopilan información personal y generalmente se configuran en respuesta a acciones que realiza en el sitio, como completar formularios o establecer sus preferencias de privacidad.",
  "footer.cookiesPolicy.subtitle3.2": "Cookies de Rendimiento",
  "footer.cookiesPolicy.text3.2":
    "Estas cookies nos permiten analizar cómo los visitantes utilizan nuestro sitio web y mejorar su funcionamiento. Recopilan información como las páginas visitadas con más frecuencia y los mensajes de error que puedan aparecer. Toda la información recopilada es anónima y se utiliza exclusivamente para mejorar el rendimiento del sitio.",
  "footer.cookiesPolicy.subtitle3.3": "Cookies de Funcionalidad",
  "footer.cookiesPolicy.text3.3":
    "Estas cookies permiten que el sitio web recuerde las elecciones que ha realizado (como su nombre de usuario, idioma o región) y proporciona características mejoradas y más personalizadas. La información recopilada por estas cookies es anónima y no rastrea su actividad de navegación en otros sitios web.",
  "footer.cookiesPolicy.subtitle3.4": "Cookies de Publicidad",
  "footer.cookiesPolicy.text3.4":
    "Estas cookies se utilizan para mostrar anuncios relevantes para usted y sus intereses. También pueden usarse para limitar la cantidad de veces que ve un anuncio y ayudar a medir la efectividad de una campaña publicitaria. Estas cookies generalmente son colocadas por redes publicitarias de terceros.",
  "footer.cookiesPolicy.title4": "Cómo controlar las cookies",
  "footer.cookiesPolicy.text4.1":
    "Puede controlar y administrar las cookies en su navegador. Tenga en cuenta que deshabilitar cookies esenciales puede afectar el funcionamiento y la experiencia de navegación en nuestro sitio web.",
  "footer.cookiesPolicy.text4.2":
    "Si desea bloquear o eliminar cookies, consulte la sección de ayuda de su navegador para obtener instrucciones sobre cómo hacerlo. Tenga en cuenta que al deshabilitar ciertas cookies, es posible que algunas características o funciones del sitio web no estén disponibles para usted.",
  "footer.cookiesPolicy.title5": "Cambios en nuestra Política de Cookies",
  "footer.cookiesPolicy.text5.1":
    "Podemos actualizar nuestra Política de Cookies ocasionalmente para reflejar cambios en nuestras prácticas y servicios. Cualquier cambio significativo se reflejará en esta página.",
  "footer.cookiesPolicy.text5.2":
    "Si tiene alguna pregunta o inquietud sobre nuestra Política de Cookies, no dude en ponerse en contacto con nosotros a través de contacto@quieromisas.com.",
  "footer.cookiesPolicy.text5.3":
    "Al continuar utilizando nuestro sitio web, usted acepta el uso de cookies de acuerdo con esta Política de Cookies.",

  // Form steps
  "formSteps.step": "Paso",
  "formSteps.step1.title": "Datos personales",
  "formSteps.step1.subtitle1": "Iniciá la constitución de tu sociedad",
  "formSteps.step1.text1":
    "Nos ocupamos de toda la documentación y te guiamos paso a paso. Para comenzar, necesitamos que nos brindes la siguiente información.",
  "formSteps.step2.title": "Denominación social",
  "formSteps.step2.subtitle1": "Elige el nombre de tu sociedad",
  "formSteps.step2.text1":
    "La denominación social es el nombre que tendrá tu Sociedad (Ej: DEPORTES S.A.S.). Indicanos tres opciones de nombre para tu Sociedad. En caso de que la primera no esté disponible, intentaremos reservar la siguiente alternativa.",
  "formSteps.step2.text2":
    "El nombre elegido será sometido a un análisis de homonimia. Este proceso lo realizará el órgano de contralor para asegurar que no exista otra sociedad con la misma denominación o que genere confusión.",
  "formSteps.step3.title": "Actividad y Sede social",
  "formSteps.step3.subtitle1":
    "Seleccioná la actividad que desarrollará tu sociedad",
  "formSteps.step3.text1":
    "Las S.A.S. permiten realizar múltiples actividades. Si bien recomendamos optar por el objeto modelo preaprobado por el órgano de contralor, podes solicitarnos la redacción de un objeto social personalizado según tus necesidades.",
  "formSteps.step3.subtitle2": "Establecé la sede social de tu sociedad",
  "formSteps.step3.text2":
    "La sede debe estar ubicada en la {jurisdiction}. Si no contás con un domicilio, podés contactarnos.",
  "formSteps.step3.checkbox.preApprovedObject": "Objeto pre-aprobado",
  "formSteps.step3.checkbox.customizedObject": "Objeto personalizado",
  "formSteps.step3.activities.construction": "Construcción",
  "formSteps.step3.activities.transportation": "Transporte",
  "formSteps.step3.activities.realEstate": "Inmobiliaria",
  "formSteps.step3.activities.finance": "Financiera",
  "formSteps.step3.activities.agriculture": "Agropecuaria",
  "formSteps.step3.activities.livestock": "Ganaderas",
  "formSteps.step3.activities.foodRelated": "Alimenticios",
  "formSteps.step3.activities.services": "Servicios",
  "formSteps.step3.activities.events": "Eventos",
  "formSteps.step3.activities.travelAndTourism": "Viajes y turismo",
  "formSteps.step3.activities.medical": "Medicina",
  "formSteps.step3.activities.publishingAndGraphics": "Editoriales y gráficas",
  "formSteps.step3.activities.industrial": "Industriales",
  "formSteps.step3.activities.importExport": "Importación y exportación",
  "formSteps.step3.activities.trusts": "Fideicomisos",
  "formSteps.step4.title": "Capital social",
  "formSteps.step4.subtitle1":
    "Indicá el capital social de tu sociedad",
  "formSteps.step4.text1":
    "El capital social de una S.A.S. no puede ser menor al equivalente a dos (2) veces el salario mínimo, vital y móvil.",
  "formSteps.step4.currentSMVM": "SMVM al día de la fecha",
  "formSteps.step4.SMVMfromDate": "SMVM a partir del {day}",
  "formSteps.step4.minimumShareCapitalWarning":
    "El capital social mínimo es de $ {number}",
  "formSteps.step4.lengthCbuWarning": "Debe contener exactamente 22 dígitos",
  "formSteps.step4.onlyDigitsCbuWarning": "Sólo puede contener dígitos",
  "formSteps.step4.validCBU": "El CBU ingresado no es válido",
  "formSteps.step4.text2":
    "Depósito inicial del capital social",
  "formSteps.step4.text3":
    "Para constituir la sociedad, es necesario depositar el 25% del capital social en una cuenta bancaria que se abrirá para este propósito. Te informaremos los datos para realizar la transferencia en su debido momento.",
  "formSteps.step4.text4":
    "Una vez constituida la sociedad, el monto depositado será devuelto a una cuenta bancaria del Administrador Titular. Por ello, más adelante te solicitaremos que nos indiques dos (2) CBU para la devolución.",
  "formSteps.step4.text5":
    "* No se permiten cuentas de bancos virtuales como Mercadopago.",
  "formSteps.step4.cbuWarning": "El CBU Principal deberá ser el del Administrador Titular. El CBU Secundario deberá corresponder a otra cuenta del Administrador Titular o a otro de los Administradores. Las cuentas de devolución ingresadas NO pueden corresponder a una Clave Virtual Uniforme (CVU). Las mismas deben corresponder a una Clave Bancaria Uniforme (CBU).",
  "formSteps.step4.ratherInformLater": "Prefiero informarlos más tarde",
  "formSteps.step5.title": "Accionistas",
  "formSteps.step5.subtitle1": "Definamos quiénes serán los accionistas",
  "formSteps.step5.text1":
    "Las S.A.S. pueden ser unipersonales o tener varios accionistas, cada uno con una cantidad específica de acciones y derechos en la sociedad. Por favor, proporcionanos la siguiente información:",
  "formSteps.step5.numberOfShareholders": "Cantidad de accionistas",
  "formSteps.step5.alert":
    "Es fundamental que los accionistas designados tengan un domicilio fiscal registrado ante la AFIP y su CUIT habilitado. En caso de no cumplir con estos requisitos, deberá regularizarse la situación para continuar con el trámite.",
  "formSteps.step5.capitalContributionRange": "Debe ser un número entre 0 y 100",
  "formSteps.step5.capitalContributionTotalMessage": "La suma de las contribuciones de capital de todos los accionistas debe ser igual a 100%",
  "formSteps.step5.maritalStatus.single": "Soltero",
  "formSteps.step5.maritalStatus.married": "Casado",
  "formSteps.step5.maritalStatus.widowed": "Viudo",
  "formSteps.step5.maritalStatus.divorced": "Divorciado",
  "formSteps.step6.title": "Administración",
  "formSteps.step6.subtitle1":
    "Determinemos quiénes serán los administradores de la Sociedad",
  "formSteps.step6.numberOfAdministrators": "Cantidad de administradores",
  "formSteps.step6.text1":
    "Se requiere mínimamente la determinación de un Administrador Titular y un Suplente. No es obligatorio que coincida con los accionistas de la Sociedad.",
  "formSteps.step6.mainAdministrator": "Administrador titular",
  "formSteps.step6.substituteAdministrator": "Administrador suplente",
  "formSteps.step6.alert":
    "Es fundamental que los accionistas designados tengan un domicilio fiscal registrado ante la AFIP y su CUIT habilitado. En caso de no cumplir con estos requisitos, deberá regularizarse la situación para continuar con el trámite.",
  "formSteps.step7.title": "Contabilidad",
  "formSteps.step7.subtitle1": "Cierre de ejercicio económico",
  "formSteps.step7.text1":
    "Las sociedades deben confeccionar anualmente estados contables que reflejen el estado de situación de la Sociedad.",
  "formSteps.step7.text2":
    "La fecha para tomar como cierre es de libre elección pero por defecto suele consignarse como fecha de cierre el 31 de diciembre de cada año.",
  "formSteps.step7.closingFiscalDate": "Fecha de cierre fiscal",
  "formSteps.step7.receiveAccountingAdvice": "¿Te gustaría recibir asesoramiento contable?",
  "formSteps.finalStep.subtitle1": "¡Listo!",
  "formSteps.finalStep.text1":
    "Nos estaremos contactando por correo electrónico o Whatsapp dentro de las próximas 48 horas hábiles para coordinar el pago y poder avanzar con la constitución de tu sociedad.",
};

export default messages;
