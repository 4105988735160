/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import AOS from 'aos';
//import GLightbox from 'glightbox';
import Isotope from 'isotope-layout';
import Swiper from 'swiper';
import imagesLoaded from 'imagesloaded';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.min.css';
import 'swiper/css/bundle';

import { Dialog } from "../../components/Dialog";
import { CookiesPolicyContent, PrivacyPolicyContent } from '../../components';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const IWANTMYSAS_HREF = "/quieroMiSas"

interface MakeStylesProps {
  isSmOrDown: boolean;
}
const useStyles = makeStyles<MakeStylesProps>()((theme, { isSmOrDown }) => ({
  text: {
    fontSize: theme.spacing(1.5),
  },
  bottomContent: {
    display: "flex",
    flexDirection: isSmOrDown ? "column" : "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  link: {
    textTransform: "uppercase",
    cursor: "pointer",
  },
  divider: {
    backgroundColor: theme.palette.divider,
    width: "2px",
    height: theme.spacing(2),
  },
}));

interface HomeContainerProps {
  children: JSX.Element;
}

const HomeContainer: FC<HomeContainerProps> = ({ children }) => {
  const intl = useIntl();
  const theme = useTheme();
  const isSmOrDown = useMediaQuery(theme.breakpoints.down("md"));
  const { classes } = useStyles({ isSmOrDown });
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openCookiesPolicy, setOpenCookiesPolicy] = useState(false);

  useEffect(() => {
    // Select DOM elements
    const selectHeader = document.querySelector('#header');
    const scrollTopBtn = document.querySelector<HTMLElement>('#scroll-top');

    // Sticky header on scroll
    const stickyHeader = () => {
      if (!selectHeader) return;
      window.scrollY > 100 ? selectHeader.classList?.add('sticked') : selectHeader.classList?.remove('sticked');
    };

    document.addEventListener('scroll', stickyHeader);

    // Scroll top button
    const toggleScrollTop = () => {
      if (!scrollTopBtn) return;
      window.scrollY > 100 ? scrollTopBtn.classList?.add('active') : scrollTopBtn.classList?.remove('active');
    };

    document.addEventListener('scroll', toggleScrollTop);
    scrollTopBtn?.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    // Mobile nav toggle
    const mobileNavShow = document.querySelector('.mobile-nav-show');
    const mobileNavHide = document.querySelector('.mobile-nav-hide');

    document.querySelectorAll('.mobile-nav-toggle').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        mobileNavToogle();
      });
    });

    function mobileNavToogle() {
      document.querySelector('body')?.classList?.toggle('mobile-nav-active');
      mobileNavShow?.classList?.toggle('d-none');
      mobileNavHide?.classList?.toggle('d-none');
    }

    // Hide mobile nav on same-page/hash links
    document.querySelectorAll('#navmenu a').forEach((navmenu) => {
      navmenu.addEventListener('click', () => {
        if (document.querySelector('.mobile-nav-active')) {
          mobileNavToogle();
        }
      });
    });

    // Toggle mobile nav dropdowns
    document.querySelectorAll('.navmenu .has-dropdown i').forEach((navmenu) => {
      navmenu.addEventListener('click', (e) => {
        if (document.querySelector('.mobile-nav-active')) {
          e.preventDefault();
          if (navmenu.parentNode instanceof Element) {
            navmenu.parentNode.classList?.toggle('active');
            navmenu.parentNode.nextElementSibling?.classList?.toggle('dropdown-active');
          }
          navmenu.classList?.toggle('bi-chevron-up');
          navmenu.classList?.toggle('bi-chevron-down');
        }
      });
    });

    // Initiate glightbox
    //const glightbox = GLightbox({
    //  selector: '.glightbox'
    //});

    //Initiate Pure Counter
    // new PureCounter();

    // Init AOS
    AOS.init({
      duration: 500,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });

    // Initiate Isotope
    let portfolionIsotope = document.querySelector('.portfolio-isotope');

    if (portfolionIsotope) {
      let portfolioFilter = portfolionIsotope.getAttribute('data-portfolio-filter') ? portfolionIsotope.getAttribute('data-portfolio-filter') : '*';
      let portfolioLayout = portfolionIsotope.getAttribute('data-portfolio-layout') ? portfolionIsotope.getAttribute('data-portfolio-layout') : 'masonry';
      let portfolioSort = portfolionIsotope.getAttribute('data-portfolio-sort') ? portfolionIsotope.getAttribute('data-portfolio-sort') : 'original-order';

      imagesLoaded(portfolionIsotope, function () {
        //@ts-ignore
        let portfolioIsotope = new Isotope(portfolionIsotope, {
          itemSelector: '.portfolio-item',
          layoutMode: portfolioLayout,
          filter: portfolioFilter,
          sortBy: portfolioSort
        });

        let menuFilters = document.querySelectorAll('.portfolio-isotope .portfolio-flters li');
        menuFilters.forEach(function (el) {
          el.addEventListener('click', function () {
            document.querySelector('.portfolio-isotope .portfolio-flters .filter-active')?.classList?.remove('filter-active');
            el.classList?.add('filter-active');
            portfolioIsotope.arrange({
              //@ts-ignore
              filter: el.getAttribute('data-filter')
            });
          }, false);
        });
      });
    }

    // Initiate swiper
    new Swiper('.testimonials-slider', {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });

    // Cleanup function
    return () => {
      document.removeEventListener('scroll', stickyHeader);
      document.removeEventListener('scroll', toggleScrollTop);
      // Add any other cleanup needed for event listeners or initialized libraries
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const handleTpItemClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.matches('.tp-item h3, .tp-item .tp-toggle')) {
        const parentNode = target.closest('.tp-item');
        parentNode?.classList.toggle('tp-active');
      }
    };

    document.addEventListener('click', handleTpItemClick);

    return () => {
      document.removeEventListener('click', handleTpItemClick);
    };
  }, []);

  return (
    <div className="index-page">

      <header id="header" className="header d-flex align-items-center sticky-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center">

          <a href="/" className="logo d-flex align-items-center me-auto">
            <img src="assets/img/logo4.png" alt="" />
            {/* <!--<h1 className="sitename">QUIERO MI SAS</h1>--> */}
          </a>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li><a href="/#inicio" className="active">Inicio<br /></a></li>
              <li><a href="/#beneficios">Beneficios</a></li>
              <li><a href="/#procedimiento">Procedimiento</a></li>
              <li><a href="/#planes">Planes</a></li>
              <li><a href="/#servicios">Servicios</a></li>
              <li><a href="/#faq">FAQ</a></li>
              <li><a href="/#contact">Contacto</a></li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>

          <a className="btn-getstarted" href={IWANTMYSAS_HREF}>QUIERO MI S.A.S.</a>

        </div>
      </header>

      <main className="main">
        {children}
      </main>

      {/* <!--
  <footer id="footer" className="footer">

    <div className="container">
      <div className="copyright text-center ">
        <p>© <span>Copyright</span> <strong className="px-1 sitename">QuieroMiSAS</strong> <span>All Rights Reserved</span></p>
		<p></p>
		<p>QuieroMiSAS presta sus servicios como intermediario para la realización de trámites legales ante la Dirección General de Inspección de Personas Jurídicas, para lo cual contrata servicios de terceros ubicados en el país, sin formar parte de ningún consorcio, entidades gubernamentales, entidades estatales, o cualquier otra clase de entidades que otorguen derechos de registrabilidad de empresas en la provincia de Córdoba; tampoco tiene alianzas, sociedad comercial o relación directa alguna con bancos en el país. QuieroMiSAS no presta ni pretende prestar ningún tipo de servicio, asesoramiento o consejo fiscal o jurídico a sus clientes y usuarios. Todo tipo de información publicada al respecto corresponde exclusivamente a información pública.</p>
      </div>
      <div className="social-links d-flex justify-content-center">
        <a href=""><i className="bi bi-twitter-x"></i></a>
        <a href=""><i className="bi bi-facebook"></i></a>
        <a href=""><i className="bi bi-instagram"></i></a>
        <a href=""><i className="bi bi-linkedin"></i></a>
      </div>
      <div className="credits">
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>

  </footer> --> */}

      <footer id="footer" className="footer position-relative">

        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-about">
              <a href="index.html" className="logo d-flex align-items-center">
                <span className="sitename"><img src="assets/img/logo4.png" alt="" /></span>
              </a>
              <p><a href="https://grupo-mw.com/" target="_blank" rel="noopener noreferrer"><img src="assets/img/grupo-mw.png" alt="" /></a></p>
              <div className="social-links d-flex mt-4">
                <a href="https://www.facebook.com/people/Quiero-Mi-SAS/61565019940355/"><i className="bi bi-facebook"></i></a>
                <a href="https://www.instagram.com/quieromisas"><i className="bi bi-instagram"></i></a>
                <a href="https://www.linkedin.com/company/martinez-wehbe/"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Enlaces</h4>
              <ul>
                <li><a href="/#">Inicio</a></li>
                <li><a href="/#beneficios">Beneficios</a></li>
                <li><a href="/#procedimiento">Procedimiento</a></li>
                <li><a href="/#planes">Planes</a></li>
                <li><a href="/#faq">FAQ</a></li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Servicios</h4>
              <ul>
                <li><a>Constitución de Sociedades</a></li>
                <li><a>Reformas Estatutarias</a></li>
                <li><a>Compras / Ventas</a></li>
                <li><a>Confección de Actas</a></li>
                <li><a>Fusión / Escisión</a></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contacto</h4>
              <p>Ituzaingó 87, 4to y 5to Piso (Centro)</p>
              <p>Gregorio Y. Gavier 1946 - Piso 1 (Cerro)</p>
              <p>Córdoba</p>
              <p>Argentina</p>
              <p className="mt-4"><strong>Teléfono:</strong> <span>+54 9 351 428 4037</span></p>
              <p><strong>Email:</strong> <span>contacto@quieromisas.com</span></p>
            </div>

          </div>
        </div>

        <div className="container copyright text-center mt-4">
          <p>© <span>Copyright</span> <strong className="sitename">Quiero MI SAS</strong> <span>All Rights Reserved</span></p> <br />
          <div className="credits"> Quiero Mi SAS presta sus servicios como intermediario para la realización de trámites legales ante la Dirección General de Inspección de Personas Jurídicas e Inspección General de Justicia, para lo cual contrata servicios de terceros ubicados en el país, sin formar parte de ningún consorcio, entidades gubernamentales, entidades estatales, o cualquier otra clase de entidades que otorguen derechos de registrabilidad de empresas en la provincia de Córdoba o la Ciudad Autónoma de Buenos Aires; tampoco tiene alianzas, sociedad comercial o relación directa alguna con bancos en el país. Quiero Mi SAS no presta ni pretende prestar ningún tipo de servicio, asesoramiento o consejo fiscal o jurídico a sus clientes y usuarios. Todo tipo de información publicada al respecto corresponde exclusivamente a información pública. <br /> <br />
            <Box className={classes.bottomContent}>
              <Box onClick={() => setOpenPrivacyPolicy(true)}>
                <Typography className={`${classes.text} ${classes.link}`}>
                  {intl.formatMessage({ id: "footer.privacyPolicy" })}
                </Typography>
              </Box>
              {!isSmOrDown && <Divider className={classes.divider} />}
              <Box onClick={() => setOpenCookiesPolicy(true)}>
                <Typography className={`${classes.text} ${classes.link}`}>
                  {intl.formatMessage({ id: "footer.cookiesPolicy" })}
                </Typography>
              </Box>
            </Box>

          </div>
        </div>

      </footer>

      <a href="/#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

      {openPrivacyPolicy && (
        <Dialog
          open={openPrivacyPolicy}
          setOpen={setOpenPrivacyPolicy}
          title={intl.formatMessage({
            id: "footer.privacyPolicy",
          })}
          content={<PrivacyPolicyContent />}
        />
      )}
      {openCookiesPolicy && (
        <Dialog
          open={openCookiesPolicy}
          setOpen={setOpenCookiesPolicy}
          title={intl.formatMessage({
            id: "footer.cookiesPolicy",
          })}
          content={<CookiesPolicyContent />}
        />
      )}

    </div>
  );
};

export default HomeContainer;
